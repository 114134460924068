
.home-container {
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    height: 150vh;
    background: url(../Images/16F0E768-F96F-45C5-9840-DE2F8AB19FBA.jfif) no-repeat top center;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
	margin-top: 15vh;
    margin-left: 15vw;
    margin-right: 15vw;
    z-index: 1;
	margin-bottom: 10vh;
}

.main-title {
    display: flex;
    flex-wrap: nowrap;
	justify-content: center;
    justify-self: center;
	justify-items: center;
	align-items: center;
	align-self: center;
    box-sizing:border-box;
	font-family: 'Aboreto', cursive;
    font-size: 9vw;
    font-weight: 500; 
    letter-spacing: -0.5vw;
	text-indent: -1vw;
    height: fit-content;
    width: stretch;
    color: whitesmoke;
	background-clip: text;
    -webkit-background-clip: text;
    margin-top: 13vh;
    line-height: 90%;
    
}

.career-title {
    box-sizing: border-box;
    width: fit-content;
    font-family: 'Sarina', cursive;
    font-size: 3vw;
    font-weight: 400; 
	color: whitesmoke;
    padding: 0;
    margin-top: 1%;
    margin-left: 2.5%;
	text-indent: -2vw;
    display: flex;
    justify-content: center;
    align-items: center;
	align-self: center;
    border-color: whitesmoke;
}

@media screen and (max-width: 1200px){

    .home-container {
        margin-left: 10vw;
        margin-right: 10vw;
    }

    .career-title {
        font-size: 4vw; 
    }

    .main-title {
        font-size: 10vw;
    }
}

@media screen and (max-width: 600px){

    .home-container {
        margin-left: 5vw;
        margin-right: 5vw;
        
    }

    .career-title {
        font-size: 4vw; 
    }

    .main-title {
        font-size: 11vw;
    }
}
