.carousel-container {
    margin-left: 15vw;
    margin-right: 15vw; 
    margin-top: 4vh;
}



.vita-home {
    margin-left: 10vw;
    margin-right: 10vw;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
}

.vita-about {
    margin-left: 10vw;
    margin-right: 10vw;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
}

.vita-calc {
    margin-left: 10vw;
    margin-right: 10vw;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
}

.vita-loading {
    margin-left: 10vw;
    margin-right: 10vw;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
}

.vita-solution {
    margin-left: 10vw;
    margin-right: 10vw;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
} 

.vita-vid-container video {
    display: flex;
    height: auto;
    width: 100%;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
}

@media screen and (max-width: 1200px){

    .contact-container {
        margin-left: 10vw;
        margin-right: 10vw;
    }
}

@media screen and (max-width: 600px){

    .contact-container {
        margin-left: 5vw;
        margin-right: 5vw;
    }
}
