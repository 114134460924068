.carousel-container {
    margin-left: 15vw;
    margin-right: 15vw; 
    margin-top: 3vh; 
    height: auto;
    overflow-y: visible;
    overflow-x: visible;
}

.carousel-root {
    border: 50px; 
    border-color: white;
} 

.carousel .slide {
    border-color: white;
    overflow-y: visible;
    overflow-x: visible;
    height: auto;
    margin-bottom: 50px;
}

.carousel .slider {
    overflow-y: visible;
    overflow-x: visible;
    margin-bottom: 50px;
}


.stone-home {
    margin-left: 10vw;
    margin-right: 10vw;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
}

.stone-about {
    margin-left: 10vw;
    margin-right: 10vw;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
}

.stone-projects {
    margin-left: 10vw;
    margin-right: 10vw;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
}

.stone-sends {
    margin-left: 10vw;
    margin-right: 10vw;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
}

.stone-add {
    margin-left: 10vw;
    margin-right: 10vw;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
}

.stone-video {
    background: no-repeat center center;
    margin-left: 10vw;
    margin-right: 10vw;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
}

.stone-vid-container video {
    display: flex;
    height: auto;
    width: 100%;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
}

.carousel .thumbs {
    display: flex;
    border: none;
    transition: none;
}

.carousel .thumbs-wrapper ul {
    display: flex;
    justify-content: space-between;
    transition: none;
    margin: 0;
    padding: 0;
}

.carousel .thumbs-wrapper {
    display: flex;
    justify-content: space-between;
    transition: none;
    margin-top: 5vh;
    /* margin-top: 5vh; */
    margin-bottom: 5vh;
    padding: 0;
}

@media screen and (max-width: 1200px){

    .carousel-container {
        margin-left: 10vw;
        margin-right: 10vw;
    }
}

@media screen and (max-width: 600px){

    .carousel-container {
        margin-left: 5vw;
        margin-right: 5vw;
    }
}

 
 