
.nav {
    position: fixed;
    top: 0;
    z-index: 100;
    width: 100%;
    background-color: whitesmoke;
} 

.nav ul {
    display: flex;
    align-items: center;
    justify-content: space-between;
    list-style: none;
    text-decoration: none;
    font-family: 'Antonio', sans-serif;
    padding-left: 15vw;
    padding-right: 15vw;
    margin-top: 5vh;
    margin-bottom: 3vh;
    font-size: 1.5rem;
    background-color: whitesmoke;
}

.nav li:visited {
    text-decoration: none;
}

.nav li {
    border: 3px solid transparent;
}

.nav li:hover {
    background-color: whitesmoke;
    color: black;
    text-decoration: none;
    border: 3px solid black;
}

.nav div.active {
    background-color: black;
    color: whitesmoke;
    text-decoration: none;
    border: 1px solid black;

}


.nav div.active:hover {
    background-color: whitesmoke;
    color: black;
    border: 1px solid  transparent;

}

@media screen and (min-width: 992px){

    .nav li {
        border: 2px solid transparent;
    }

    .nav li:hover {
        background-color: whitesmoke;
        color: black;
        text-decoration: none;
        border: 2px solid black;
    }

    .nav div.active:hover {
        background-color: whitesmoke;
        color: black;
        border: 1px solid  transparent;
    
    }

}

@media screen and (max-width: 1200px){

    .nav ul {
        padding-left: 10vw;
        padding-right: 10vw;
    }
    
    .nav li {
        border: 2px solid transparent;
    }

    .nav li:hover {
        background-color: whitesmoke;
        color: black;
        text-decoration: none;
        border: 2px solid black;
    }

    .nav div.active:hover {
        background-color: whitesmoke;
        color: black;
        border: 1px solid  transparent;
    
    }
}

@media screen and (max-width: 600px){

    .nav ul {
        font-size: 1rem;
        padding-left: 5vw;
        padding-right: 5vw;
    }

    .nav li {
        border: 1px solid transparent;
    }

    .nav li:hover {
        background-color: whitesmoke;
        color: black;
        text-decoration: none;
        border: 1px solid black;
    }

    .nav div.active:hover {
        background-color: whitesmoke;
        color: black;
        border: 1px solid  transparent;
    
    }
}


