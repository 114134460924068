.contact-container {
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    margin-top: 15vh;
    height: 150vh;
    background: url(../Images/16F0E768-F96F-45C5-9840-DE2F8AB19FBA-grayscale.jfif) no-repeat top center;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    margin-left: 15vw;
    margin-right: 15vw;
    margin-bottom: 15vh;
}


.contact-me {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: flex-start;
    box-sizing:border-box;
    background: transparent;
    font-family: 'Sarina', cursive;
    font-size: 9vw;
    font-weight: 500;
    letter-spacing: -1vw;
    height: fit-content;
    max-width: fit-content;
    color: black;
    padding: 5vw;
    line-height: 80%;
}

.contact-info {
    display: flex;
    flex-direction: column;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
	font-family: 'Aboreto', cursive;
    height: 100vh;
    font-size: xx-large;
    font-weight: 500; 
    letter-spacing: -0.1vw;
    color: black;
    padding: 5vw;
}

.contact-info a {
    background-color: #fdd9db;
    padding: 5vh;
    margin-top: 3vh;
    -webkit-transition: .3s ease-in-out;
	transition: .3s ease-in-out;
}

.contact-info a:hover {
    -webkit-transform: scale(1.1);
	transform: scale(1.1);
    transform-origin: center;
}

@media screen and (max-width: 1200px){

    .contact-container {
        margin-left: 10vw;
        margin-right: 10vw;
    }
}

@media screen and (max-width: 600px){

    .contact-container {
        margin-left: 5vw;
        margin-right: 5vw;
    }
}