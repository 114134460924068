.about-container {
    background-color: #fdd9db;
    margin-left: 15vw;
    margin-right: 15vw;
    margin-top: 15vh;
    margin-bottom: 10vh;
    /* height: auto; */
    display: flex;
    flex-wrap: nowrap;
    flex-direction: row;
    justify-content: space-between;
    padding-top: 10vh;
    padding-left: 5vw;
    padding-right: 5vw;
    padding-bottom: 50vh;
    font-family: 'Playfair Display', serif;
    font-weight: 500;
    font-size: 1.2rem;
    box-sizing: border-box;

 
}

.intro-pic {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 80%;
    padding: 0;
    margin-top: 0;
    border-radius: 50%;
}

.intro-pic img {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    border-radius: 50%;
    border-width: 4px;
    border-style: solid;
    padding: 12px;
    border-color: black;
    background-position: center center;
    position: center center;
    background-repeat: no-repeat;
    background-size: contain;
    height: auto;
    width: 100%;
    margin-top: 0;
    }

.intro {
    font-family: 'Sarina', cursive;
    font-size: 200%;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    width: 100%;
    height: max-content;
    margin: 0;
    margin-bottom: 5vh;
    padding: 0;
    text-align: left;
}


.blurb {
    display:flex;
    flex-direction: column;
    background-repeat: no-repeat;
    background-size: contain;
    -webkit-transform: scale(1);
	transform: scale(1);
    transform-origin: center;
	-webkit-transition: .3s ease-in-out;
	transition: .3s ease-in-out;
    overflow: visible;
    text-align: justify;
    height: auto;
}

#bio {
    margin-top: 0;
}


@media screen and (min-width: 992px){
    .blurb {
        width: 45%;
        height: 70vh;
        margin-bottom: 1vh;
        background-clip: border-box;
    }

    .intro-pic {
        width: 45%;
        margin-bottom: 1vh;
        background-clip: border-box;
    }

}

@media screen and (max-width: 1550px){
    .blurb {
        width: 100%;
        margin-top: 5vh;
        background-clip: border-box;
    }

    .intro-pic {
        width: 60%;
        margin-top: 0vh;
        padding-left: 15vw;
        background-clip: border-box;
    }

    .about-container {
        flex-direction: column-reverse;
        margin-left: 15vw;
        margin-right: 15vw;
    }
}

@media screen and (max-width: 1200px){

    .about-container {
        margin-left: 10vw;
        margin-right: 10vw;
    }
}

@media screen and (max-width: 600px){
    .blurb {
        width: 100%;
        margin-top: 5vh;
        background-clip: border-box;
    }

    .intro-pic {
        width: 80%;
        margin-top: -5vh;
        padding-left: 7vw;
        padding-right: 2vw;
        background-clip: border-box;
    }

    .about-container {
        flex-direction: column-reverse;
        margin-left: 5vw;
        margin-right: 5vw;
    }
}