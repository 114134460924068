.skill-icons {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    box-sizing: border-box;
    height: 150vh;
    background: url(../Images/16F0E768-F96F-45C5-9840-DE2F8AB19FBA.jfif) no-repeat top center;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    margin-top: 15vh;
    margin-left: 15vw;
    margin-right: 15vw;
    margin-bottom: 10vh;
    align-content: flex-start;
    align-items:flex-start;
}

.icon-box {
    display: flex;
    box-sizing: border-box;
    flex-direction: row;
    flex-wrap: wrap;
    height: fit-content;
    align-items: flex-start;
    justify-content: space-evenly;
}

svg {
    display: flex;
    padding: 4%;
    margin: 9%;
    background-color: rgba(255, 255, 255, 0.664);
    -webkit-transition: .3s ease-in;
	transition: .3s ease-in;
}

svg:hover {
    -webkit-transform: scale(1.2);
	transform: scale(1.2);
    transform-origin: center;
}

@media screen and (min-width: 992px){
    .skill-icons {
        height: 150vh;
    }

    .icon-box {
        flex-direction: row;
        margin: 4%;
    }

    svg {
        width: 7%;
        padding: 2%;
        margin: 4%;
    }
}

@media screen and (max-width: 1200px){

    .icon-box {
        flex-direction: row;
    }

    svg {
        width: 10vw;
    }

    .skill-icons {
        margin-left: 10vw;
        margin-right: 10vw;
    }
}

@media screen and (max-width: 600px){

    svg {
        width: 10vw;
    }

    .skill-icons {
        margin-left: 5vw;
        margin-right: 5vw;
    }
}
