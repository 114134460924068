.project-container {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: space-between;
    box-sizing: border-box;
    margin-top: 20vh;
    /* padding-left: 15vw;
    padding-right: 15vw; */
    margin-left: 15vw;
    margin-right: 15vw;
    background-color: whitesmoke;
    margin-bottom: 10vh;
 
}

figure {
    margin: 0;
    width: 49%;
}

figcaption {
    width: 100%;
    margin-right: 0;
    margin-top: 2%;
    margin-bottom: 10%;
    box-sizing: border-box;
    font-family: 'Sarina', cursive;
    font-weight: 500;
    letter-spacing: -0.2vw;
    font-size: xx-large;
}

.vitamin-sol {
    display: flex;
    margin-right: 0;
    width: 100%;
    -webkit-transform: scale(1);
	transform: scale(1);
    transform-origin: center;
	-webkit-transition: .3s ease-in;
	transition: .3s ease-in;
    font-size: xx-large;
    }

.vitamin-sol img {
    margin-right: 0;
    width: 100%;
}

.vitamin-sol:hover {
    -webkit-transform: scale(.95);
    transform: scale(.95);
    transform-origin: center;
    
}

.stone-babes {
    display:flex;
    width: 100%;
    -webkit-transform: scale(1);
	transform: scale(1);
    transform-origin: center;
	-webkit-transition: .3s ease-in;
	transition: .3s ease-in;
    overflow: hidden;
}

.stone-babes img {
    margin-right: 0;
    width: 100%;
}



.stone-babes:hover {
    -webkit-transform: scale(.95);
	transform: scale(.95);
    transform-origin: center;
    
}


.back-button {
    background-clip: border-box;
    background-color: black;
    color:whitesmoke;
    margin-left:calc(50% - 4.5rem);
    border-radius: 50%;
    height: 9rem;
    width: 9rem;
    margin-top: 20vh;
    margin-bottom: -5vh;
    font-family: 'Sarina', cursive;
    font-size: 0.9rem;
    font-weight: 500; 
    -webkit-transform: scale(1);
	transform: scale(1);
    transform-origin: center;
	-webkit-transition: .3s ease-in;
	transition: .3s ease-in;
    position:relative;
    z-index: 10;
}


.back-button:hover {
    -webkit-transform: scale(1.2);
    transform: scale(1.2);
    transform-origin: center;
    
}

@media screen and (min-width: 992px){

    figure {
        width: 49%;
    }

    .project-container {
        margin-top: 30vh;
    }

}

@media screen and (max-width: 1200px){

    figure {
        width: 100%;
        margin-top: 5vh;
    }

    .project-container {
        margin-top: 20vh;
        margin-left: 10vw;
        margin-right: 10vw;
    }

    
    .back-button {
        margin-left: calc(50% - 3.5rem);
        height: 7rem;
        width: 7rem;
        font-size: 0.7rem;
    }
}

@media screen and (max-width: 600px){

    figure {
        width: 100%;
    }
    .project-container {
        margin-top: 15vh;
        margin-left: 5vw;
        margin-right: 5vw;
    }

    .back-button {
        margin-left: calc(50% - 2.5rem);
        margin-top: 17vh;
        height: 5rem;
        width: 5rem;
        font-size: 0.5rem;
    }
}